h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

.Product {
    background-color: black;
    width: 100%;
}

.TitleAndLogo {
    margin-top: -1rem;
}

.TimeChainImage {
    width: 108px;
    height: 108px;
    z-index: 5;
}

.CompanyTitle {
    font-family: 'IBM Plex Sans';
    font-size: 48;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
    letter-spacing: -3%;
    color: #FFFFFF;
    z-index: 5;
    margin-bottom: 0;
}

.CompanyTitleBold {
    font-weight: 700;
}

.TCTSubtitle {
    text-transform: uppercase;
    font-family: 'IBM Plex Mono';
    font-size: 15.83px;
    color: #8E8E93;
    line-height: 32px;
    letter-spacing: -0.5%;
}

.ProductH2 {
    text-align: center;
}

.ProductsAndServices {
    margin-top: 8rem;
    padding: 10px;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    color: #FFFFFF;
}

.Introducing, .ProductListingTwo  {
    text-align: center;
    margin-top: 3rem;
    /* margin-bottom: -2rem; */
    font-family: 'Inter';
    font-weight:600;
    color: #8E8E93;
}

.allcards {
    margin-top: -3rem;
}

.allcardstwo {
    margin-top: -8rem;
}

.CurrentRow {
    margin-top: -2rem;
}

.Building {
    margin-top: -7rem;
}

.CardImages, .ProductLogo {
    width: 100px;
    height: 100px;
}

.Card {
    padding: 32px;
    padding-bottom: 0px;
    margin: 25px 8px 16px;
    height: 100%;
    background: #1C1C1E;
    border: 1px solid #2C2C2E;
    border-radius: 32px;
}

.card-body {
    text-align: start;
}

.col-12 {
    margin-top: 2rem;
}

.card-title, .ProductTitleBold {
    padding-top: 1rem;
}

.card-title, .ProductTitleBold, .NNProductTitle, .NNProductTitleBold, .NNProductTitleBoldTwo, .NNProductTitleTwoNodes {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.NNDiv {
    padding-top: 16px;
}

.ProductTitleBold, .NNProductTitleBold, .NNProductTitleBoldTwo {
    font-weight: 700;
} 

.Subtitle {
    margin-top: 1rem !important;
}

.ProductTagLine, .card-subtitle {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 1rem;
    letter-spacing: -3%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 0px !important;
    margin-top: 1rem;
}

.card-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Inter'; 
    font-size: 16;
    line-height: 1.2rem;
    letter-spacing: -3%;
    color: #8E8E93;
}

.AmberAppPopout, .VoltPayPopout, .AmberNodesPopout, .TwitterSpaces {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-bottom: 4px;
}

.Podcast {
    width: 12px;
    height: 16px;
    margin-right: 5px;
    margin-bottom: 4px;
}

.VoltPayProductLink, .AmberAppProductLink, .AmberNodesProductLink, .TribeTalkProductLink, .TribeTalkProductLinkTwo {
    text-decoration: none;
    border-radius: 25px;
    padding: 8px 16px;
    text-transform: uppercase;
    font-family: 'IBM Plex Mono';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

}

.AmberAppProductLinkDiv {
    margin-top: 7.7rem;
}

.AmberNodesProductLinkDiv {
    margin-top: 6.5rem;
}

.VoltPayProductLinkDiv {
    margin-top: 5.3rem;
}

.TribeTalkLinkDiv {
    margin-top: 6.5rem;
}

.VoltPayProductLink {
    background: linear-gradient(0deg, #2C2C2E, #2C2C2E),
    linear-gradient(0deg, rgba(242, 169, 0, 0.24), rgba(242, 169, 0, 0.24));
    color: #F2A900;
    border: 1px solid #F2A9003D;
}

.VoltPayProductLinkDiv {
    margin-top: 6.2rem;
}

.AmberAppProductLink {
    background: linear-gradient(0deg, #2C2C2E, #2C2C2E),
    linear-gradient(0deg, rgba(255, 135, 0, 0.24), rgba(255, 135, 0, 0.24));
    color: #FF8700;
    border: 1px solid #FF87003D;
}

.AmberNodesProductLink {
    background: linear-gradient(0deg, #2C2C2E, #2C2C2E),
    linear-gradient(0deg, rgba(255, 135, 0, 0.24), rgba(255, 135, 0, 0.24));
    color: #007AFF;
    border: 1px solid #007AFF;
}

.TribeTalkProductLink, .TribeTalkProductLinkTwo {
    background: linear-gradient(0deg, #2C2C2E, #2C2C2E),
    linear-gradient(0deg, rgba(175, 82, 222, 0.24), rgba(175, 82, 222, 0.24));
    color: #AF52DE;
    border: 1px solid #AF52DE3D;
}

.TribeTalkProductLink {
    margin-right: 8px;
}

.CurrentlyBuilding {
    margin-top: 10rem;
    margin-bottom: 5rem;
}

.FooterIPTwo {
    margin-top: 12rem;
    margin-bottom: 8rem;
}

@media (min-width: 1400px) {
    .NNProductBoldTitleTwo, .NNProductTitleTwoNodes {
        font-size: 32px;
    }
}

@media (max-width: 1400px) {
    .TribeTalkLinkDiv {
        display: flex;
        flex-direction: column;
    }

    .TribeTalkProductLink, .TribeTalkProductLinkTwo {
        width: fit-content
    }

    .TribeTalkProductLinkTwo {
        margin-top: 1rem;
    }

    .AmberAppProductLinkDiv {
        margin-top: 11.7rem;
    }

    .AmberNodesProductLinkDiv {
        margin-top: 8.1rem;
    }

    .VoltPayProductLinkDiv {
        margin-top: 9.5rem;
    }

}

@media (max-width: 1399px) {

    .VoltPayProductLinkDiv {
        margin-top: 12rem;
    }

    .TribeTalkLinkDiv {
        margin-top: 6.6rem;
    }

}

@media (min-width: 1400px) {
    .NNProductTitle, .NNProductTitleBold {
        display: none;
    }
}

@media (min-width: 999px) and (max-width: 1399px) {
    .NNProductTitleTwo, .NNProductTitleBoldTwo {
        display: none;
    }

    .NNProductTitle {
        padding-top: 1rem;
    }
}

@media (min-width: 999px) and (max-width: 1399px) {
    .NNDiv {
        padding-top: 0px;
    }
}

@media (max-width: 999px) {
    .NNProductTitleTwo, .NNProductTitleBoldTwo {
        display: none;
    }
}

@media (max-width: 1199px) {
    .AmberAppProductLinkDiv {
        margin-top: 12rem;
    }

    .VoltPayProductLinkDiv {
        margin-top: 9.4rem;
    }

}

@media (max-width: 992px) {
    .Card {
        min-width: 22rem;
    }

    .card-body {
        text-align: center;
    }

    .AmberAppProductLinkDiv {
        margin-top: 11rem;
    }

    .AmberNodesProductLinkDiv {
        margin-top: 6.8rem;
        display: flex;
        justify-content: center;
    }

    .TribeTalkLinkDiv {
        align-items: center;
    }
}

@media (max-width: 768px) {
    .Card {
        min-height: 25rem;
    }

    .NNProductTitle, .NNProductTitleBold {
        display: none;
    }

    .NNProductTitleBoldTwo, .NNProductTitleTwo {
        display: flex;
        align-items: center;
        justify-content: center; 
    }
}

@media (max-width: 768px) {
    .AmberAppProductLinkDiv, .VoltPayProductLinkDiv, .TribeTalkLinkDiv,  .AmberNodesProductLinkDiv {
        margin-top: 5rem;
    }
}

@media (max-width: 380px) {
    
    .Card {
        min-height: 0rem;
        min-width: 0rem;
    }

    .Email {
        font-size: 12px;
    }

    .CompanyTitle, .CompanyTitleBold {
        font-size: 1.6rem
    }  

    .TCTSubtitle {
        font-size: 0.8rem;
    }

    .Roadmap, .Contact  {
        font-size: 1.5rem
    }

    .FooterIPTwo {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
}

@media (max-width: 300px) {

    .CompanyTitle, .CompanyTitleBold {
        font-size: 1.4rem
    }  

    .TCTSubtitle {
        font-size: 0.7rem;
    }

    .Roadmap, .Contact  {
        font-size: 1.2rem
    }

    .NNProductTitle, .NNProductTitleBold {
        display: flex;
        flex-direction: column;
        line-height: 1rem;
    }

    .NNDiv {
        padding-top: 24px;
    }

    .NNProductTitleBoldTwo, .NNProductTitleTwo {
        display: none;
    }

    .ProductsAndServices {
        font-size: 24px;
    }
    
}