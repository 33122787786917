/* Reset some default styles */
body, html {
    margin: 0;
    padding: 0;
}
  
html {
    background-color: black;
}
  
.HJMainDiv {
    text-align: center;
    min-width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.HJTitleAndLogo.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.TCTSubtitleProductDiv {
    text-decoration: none;
}

.HJTitleAndLogo.container a {
    text-decoration: none;
}

.HJTitleAndLogo {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
}

.HJLogoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px !important;
    margin-top: 5rem !important;
}

.TCTSubtitleHome {
    font-size: 14px;
    color: #8E8E93;
}

.CompanyTitleTCT,
.CompanyTitleBoldTCT {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    z-index: 5;
    margin-bottom: 0;
    padding-bottom: 0;
}

.CompanyTitleTCT {
    font-weight: 400;
    font-size: 48px;
    letter-spacing: -3%;
}

.CompanyTitleBoldTCT {
    font-weight: 700;
    font-size: 48px;
    letter-spacing: -0.03em;
}

.TimeChainImage {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
}

.TCTSubtitleHome {
    text-transform: uppercase;
    font-family: 'IBM Plex Mono';
    font-size: 15.83px;
    color: #8E8E93;
    line-height: 32px;
    letter-spacing: -0.5%;
    margin-top: 12px;
    margin-bottom: 0;
}

.HJTitleDiv {
    width: 100%;
    height: calc(1620px + 200px);
    overflow-x: hidden; /* Hide horizontal scrollbar, if it breaks, this is why */
    overflow-y: auto;
    text-align: center;
    scrollbar-color: #007 #bada55;
    margin-top: 0px !important;
    /* padding: 20px; */
}

#hero-journey-svg:active {
    cursor: grabbing;
}

/* Pointer cursor for node elements */
a, g, #hero-journey-svg g[data-gray-title][data-gray-text] {
    cursor: pointer;
  }

.HJExplainerTitle {
    font-family: 'IBM Plex Mono';
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    margin-bottom: 8px;
}

.HJExplainerText {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 24px;
    color: #8E8E93;
}

.grayDiv {
    padding: 24px;
    background-color: #1C1C1E;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    text-align: start;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: absolute;
    border: 2px solid #2C2C2E;
    visibility: hidden;
    max-width: 150px;
    max-height: 80vh; 
    overflow: auto;
    /* margin-top: 200px; */
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 1.5s linear;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%); 
}

.grayDiv.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.4s linear 0.2s;
    max-width: 350px;
    min-width: 200px; 
    border-color: #FF9F0A;
}

.grayDiv.viewport-limited {
    max-height: 80vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grayDivContent {
    width: 100%;
}

.bottomSpacer {
    width: 100%;
    height: 80px;
    background-color: black;
}

#hero-journey-svg {
    cursor: default;
    display: block;
    margin: 0 auto;
  }
 
.hjvidbg {
    position: fixed;
    max-width: 1632px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden;
}

.hjvidbg .vignette {
    position: absolute;
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
}

.hjvidbg .LifesSeed {
    z-index:-1;
    opacity:0.2;
}

  /* Grab cursor for smaller screens */
  @media screen and (max-width: 1400px) {
    #hero-journey-svg {
      cursor: grab;
    }
  }


@media (min-width: 991px) {
    .HJLogoDiv {
        margin-left: 5rem;
        margin-top: 0px;
    }

    .HJMainDiv {
        height: 100%;
    }
}


@media (max-width: 991px) {  
    .HJTitleDiv {
        overflow:auto;
        height:auto;
    }

    #hero-journey-svg {
        max-width:100%;
        height:auto;
    }
    
    .CompanyTitleTCT, .CompanyTitleBoldTCT {
        font-size: 40px;
    }

    .grayDiv.show {
        scale: 0.3;
        transform-origin: top left;
    }

    .hjvidbg .vignette {
        display:none;
    }

    .hjvidbg .LifesSeed {
        opacity:0.1;
    }
}

@media (max-width: 991px) {  
    .HJMainDiv {
        height: 100%;
        min-height: 0px;
    }

    .HJLogoDiv {
        margin-top: 40px !important;
    }
}

@media (max-width: 767px) {
    .HJTitleDiv {
        margin-left: 0; 
        margin-right: 0; 
    }
    
    .CompanyTitleTCT, .CompanyTitleBoldTCT {
        font-size: 32px;
    }
}

@media (max-width: 400px) {
    .HJLogoDiv {
        margin-top: 40px !important;
    }
}