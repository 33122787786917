#videobackground, video, .TimeChain {
    object-fit: contain;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}


video {
    margin-top: -14rem;
}

body, .TimeChain, .ProductRoadmap {
    overflow-x: hidden;
    width: 100%;
}

#videobackground {
    max-width:100%;
}

html {
    background-color: black;
}

.TitleAndLogoTimeChain{
    align-items: center;
}

.TimeChainImageTCT {
    width: 81px;
    height: 108px;
    z-index: 5;
    margin-top: 28rem;
}

.SetupDiv {
    margin-top: 4rem;
}

.JourneyDiv {
    margin-top: 29.5rem;
}

.TCTSubtitleHomeDiv {
    text-decoration: none;
    padding: none;
    margin: none;
    margin: 0px !important;
    padding: 0px !important;
}

.TCTSubtitleHome {
    text-transform: uppercase;
    font-family: 'IBM Plex Mono';
    font-size: 15.83px;
    color: #8E8E93;
    line-height: 32px;
    letter-spacing: -0.5%;
    margin-top: 12px;
    margin-bottom: 0px;
}

.Setup, .CompanyTitle, .TitleAndLogo, .text-center, .FooterIP {
    z-index: 50;
}

.CompanyTitleTCT {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    font-size: 48px;
    text-align: center;
    letter-spacing: -3%;
    color: #FFFFFF;
    /* margin-top: -1.5rem; */
    z-index: 5;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.CompanyTitleBoldTCT {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    font-size: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    /* margin-top: -1.5rem; */
    z-index: 5;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.Setup, .Journey, .Journey2 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: bold;
    text-align: center;
    letter-spacing: -3%;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.DefaultText, .SmallText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    text-align: center;
    letter-spacing: -3%;
    color: #8E8E93;
}

.DefaultTextDiv, .JourneySmallText {
    width: 60%;
}

.FooterIP {
    margin-top: 17rem;
    margin-bottom: 15rem;
}

.HeroJourney, .HeroJourney2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    text-align: center;
    letter-spacing: -3%;
    /* Color/Orange */
    color: #FF8700;
    text-decoration: none;
}

.Copyright {
    color: #48484A;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    padding-top: 1rem;
}

.Contact {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-size: 40px;
    font-weight: 700 !important;
    text-align: center;
    letter-spacing: -3%;
    color: white;
}

.Email{
    font-family: 'Inter';;
    font-style: normal;
    line-height: 32px;
    font-size: 24px;
    font-weight: 600 !important;
    letter-spacing: 3%;
    color: #FF8700;
    text-decoration: none;
}

.Email{
    padding-top: 8px;
    padding-bottom: 8px;
}

.ClosingInfo {
    font-weight: 100 !important;
}

.TimeChainImageFooter {
    width: 64px;
    height: 64px;
    margin: 1rem;
}

/* Height Media queries */

@media (max-height: 1150px) {
    video {
        margin-top: -10rem;
    }

    .TimeChainImageTCT {
        margin-top: 38rem;
    }
}

@media (max-height: 1050px) {
    video {
        margin-top: -7rem;
    }

    .TimeChainImageTCT {
        margin-top: 45.5rem;
    }
}

@media (max-height: 950px) {
    video {
        margin-top: -5rem;
    }

    .TimeChainImageTCT {
        margin-top: 50.5rem;
    }
}

@media (max-height: 900px) {
    video {
        margin-top: -2rem;
    }

    .TimeChainImageTCT {
        margin-top: 58rem;
    }
}

@media (max-height: 800px) {
    video {
        margin-top: 0rem;
    }

    .TimeChainImageTCT {
        margin-top: 63rem;
    }
}

@media (max-height: 700px) {
    video {
        margin-top: 3rem;
    }

    .TimeChainImageTCT {
        margin-top: 70.5rem;
    }
}

@media (max-height: 700px) {
    video {
        margin-top: 3rem;
    }

    .TimeChainImageTCT {
        margin-top: 70.5rem;
    }
}

@media (max-height: 600px) {
    video {
        margin-top: 3rem;
    }

    .TimeChainImageTCT {
        margin-top: 70.5rem;
    }
}

/* Width media queries */

@media (max-width: 1227px) {
    .TimeChainImageTCT {
        margin-top: 47.5rem;
    }

    video {
        margin-top: -6.5rem;
    }

    .SetupDiv {
        margin-top: 4rem;
    }

    .JourneyDiv {
        margin-top: 30rem;
    } 
}

@media only screen  and (max-width: 845px) and (orientation: landscape) {

    video {
        margin-top: 12rem !important;
    }

    .TimeChainImageTCT {
        margin-top: 93.5rem !important;
    }

    body {
        background-color: black !important;
    }

    .JourneyDiv {
        margin-top: 30rem !important;
    }

    /* body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        overflow-x: hidden;
        background-color: black !important;
    }*/

    /* body {
        overflow: hidden;
        background-color: black !important;
    }

    .TCTHome {
        overflow: hidden;
        width: 100%;
    } 

    video {
        margin-top: -15rem;
        width: 100%;
    }

    .SetupDiv {
        display: none;
    }

    .JourneyDiv {
        display: none;
    } 

    .TimeChainImageTCT {
        display: none;
    }

    .FooterIP {
        margin-top: 50rem;
    } */

    /* #videobackground, video, .TimeChain {
        /* position: static;  */
        /* object-fit: contain; */
        /* height: auto;
        width: auto; */
  
        /* #videobackground {
            width: 100%;
            height: auto;
            position: relative;
            top: 82rem;
            overflow: visible;
        }

        .TimeChainImageTCT {
            margin-top: -5.25rem !important;
            width: 81px;
            height: 81px;
        }

    .CompanyTitleTCT {
        display: flex;
        flex-direction: column;
        line-height: 40px; */
        /* margin-top: 10rem; */
    /* }

    .Setup, .Journey, .Contact, .Journey2 {
        font-size: 32px;
        line-height: 40px;
    }

    .HeroJourney, .HeroJourney2, .Email {
        font-size: 16px;
    }

    .SetupDiv {
        margin-top: 2.25rem !important;
    }

    .JourneyDiv {
        margin-top: 30.5rem !important;
    } 

    .FooterIP {
        margin-top: 3.75rem;
        margin-bottom: 3rem;
    } */
}

@media (max-width: 768px) {

    .TimeChainImageTCT {
        margin-top: 37.5rem;
    }

    video {
        margin-top: -4.5rem;
    }

    .SetupDiv {
        margin-top: 1rem;
    }

    .JourneyDiv {
        margin-top: 29.5rem;
    } 

    .SetupDiv {
        margin-top: 1rem;
    }

    .JourneyDiv {
        margin-top: 20rem;
    } 
}



@media (max-width: 600px) {

   .TimeChainImageTCT{
        margin-top: 55.5rem;
    }

    .JourneyDiv {
        margin-top: 30rem;
    } 

    .Contact {
        font-size: 40px;
    }

    .Email {
        font-size: 24px;
    }

    .Copyright {
        font-size: 16px;
    }

    .TimeChainImageFooter {
        margin-top: 0px;
    }

    .FooterIP {
        margin-top: 20rem;
    }
}

@media (max-width: 590px) {

    .TimeChainImageTCT{
        margin-top: 54.25rem;
    }

    .JourneyDiv {
        margin-top: 30.5rem;
    } 

    .SetupDiv {
        margin-top: 2.25rem;
    }

    .CompanyTitleTCT {
        display: flex;
        flex-direction: column;
        line-height: 40px;
    }

    .Setup, .Journey, .Contact, .Journey2 {
        font-size: 32px;
        line-height: 40px;
    }

    .HeroJourney, .HeroJourney2, .Email {
        font-size: 16px;
    }
}

@media (max-width: 510px) {

    .TimeChainImageTCT{
        margin-top: 54.5rem;
    }

    .Setup {
        margin-top: 0rem;
    }

    .JourneyDiv {
        margin-top: 30.5rem;
    } 
}

@media (max-width: 495px) {

    .TimeChainImageTCT{
        margin-top: 54.5rem;
    }

    .Setup {
        margin-top: -2.5rem;
    }

    .JourneyDiv {
        margin-top: 30.5rem;
    } 
}

@media (max-width: 475px) {
    .TimeChainImageTCT{
        margin-top: 68.75rem;
    }

    #videobackground, video, .TimeChain {
        top: 63%;
    }

    .Setup {
        margin-top: -2rem;
    }

    .JourneyDiv {
        margin-top: 30.25rem;
    } 
}

@media (max-width: 390px) {

    .TimeChainImageTCT{
        margin-top: 69.5rem;
    }

    #videobackground, video, .TimeChain {
        top: 63.25%;
    }

    .Setup {
        margin-top: -2.5rem;
    }

    .JourneyDiv {
        margin-top: 30.5rem;
    } 

    .Setup, .Journey, .Journey2 {
        font-size: 32px;
    }

    .HeroJourney, .HeroJourney2 {
        font-size: 16px;
        line-height: 32px;
    }
}

@media (max-width: 330px) {

    .CompanyTitleTCT, .CompanyTitleBoldTCT {
        font-size: 48px;
        line-height: 40px;
    }

    .TCTSubtitleHome {
        margin-top: 8px;
    }

    .Setup, .Journey, .Journey2 {
        font-size: 32px;
        line-height: 40px;
    }
    
    .HeroJourney, .HeroJourney2 {
        font-size: 16px;
        line-height: 32px;
    }
}

@media (max-width: 325px) {
    
    .TimeChainImageTCT{
        margin-top: 62.5rem;
    }

    .CompanyTitleTCT {
        margin-top: -0.5rem;
    }

    .Setup {
        margin-top: -2rem;
    }

    .JourneyDiv {
        margin-top: 28rem;
    } 

    .CompanyTitleTCT, .CompanyTitleBoldTCT {
        font-size: 28px;
        line-height: 28x;
    }

}

@media (max-width: 318px) {

    .TimeChainImageTCT{
        margin-top: 62.5rem;
    }
}

@media (max-width: 310px) {

    .TimeChainImageTCT{
        margin-top: 53rem;
    }

    video {
        margin-top: -15rem;
    }
}

@media (max-width: 303px) {
    
    .TimeChainImageTCT{
        margin-top: 50.5rem;
    }

    video {
        margin-top: -15rem;
    }

    .CompanyTitleTCT {
        margin-top: -0.5rem;
    }

    .Setup {
        margin-top: -4rem;
    }

    .JourneyDiv {
        margin-top: 28rem;
    } 

    .CompanyTitleTCT, .CompanyTitleBoldTCT {
        font-size: 28px;
        line-height: 28px !important;
    }

    .Setup .Journey2 {
        font-size: 24px;
        line-height: 24px;
    }

}

@media (max-width: 251px) {

    .TimeChainImageTCT{
        margin-top: 54.5rem;
    }
}